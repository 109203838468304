<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Dupliceer evenement</h1>

        <b-alert variant="info" show>
            <font-awesome-icon icon="fa-solid fa-info-circle" /> Bij het dupliceren van een evenement wordt het evenement met bijbehorende tickets gedupliceerd. De geldigheid- en verkoopdata van de tickets worden ook overgenomen en aangepast aan de hand van de nieuwe datum van het evenement.
        </b-alert>

        <ValidationObserver ref="form" slim>
            <EventDetails :form="form" />
            <EventLocation :form="form" />
            <EventDates :form="form" :duplicate="true" />
            <EventSaleDate :form="form" />
            <EventOptions :form="form" />
            <EventMarketing :form="form" />
    
            <div class="clearfix">
                <b-button @click="submitForm" variant="primary" class="float-right">
                    Dupliceer
                    <font-awesome-icon icon="fa-solid fa-chevron-right" />
                </b-button>
            </div>
        </ValidationObserver>
        
      </b-container>
    </div>
  </template>
  
  <script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    import { ValidationObserver } from 'vee-validate';
    import EventDetails from '@/components/event/EventDetails.vue'
    import EventLocation from '@/components/event/EventLocation.vue'
    import EventSaleDate from '@/components/event/EventSaleDate.vue'
    import EventDates from '@/components/event/EventDates.vue'
    import EventOptions from '@/components/event/EventOptions.vue'
    import EventMarketing from '@/components/event/EventMarketing.vue'

    export default {
        metaInfo: {
            title: 'Dupliceer',
        },
        components: {
            ValidationObserver,
            EventDetails,
            EventLocation,
            EventSaleDate,
            EventDates,
            EventOptions,
            EventMarketing,
        },
        data() {
            return {
                form: {
                    slots: [],
                    tickets: [],
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        this.form = response.data;
                        this.setLoading(false);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            async submitForm() {
                let check = await this.$refs['form'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    return;
                }

                this.$axios.post("https://api.tixgo.nl/events", this.form)
                    .then( response => {
                        response;
                        this.$router.push('/manage/events');
                    })
                ;
            }
        },
        created() {
            this.fetchEvent();
        },
    }
</script>